<template>
    <!-- PAGE HEADER -->
	<div class="page_header"></div>
	<!-- // PAGE HEADER -->

    <div class="container single-post padding-bottom-30">
		<div class="row">

			<div class="clearfix"></div>
			<div class="col-md-8 col-sm-7 padding-bottom-30">
				<div class="blog-excerpt">
					<div class="blog-single-head">
						<Skeleton v-if="skeleton.post" height="4rem" width="100%" class="mb-4" borderRadius="10px"></Skeleton>
						<h2 v-else>{{ post.title }}</h2>
						<Skeleton v-if="skeleton.post" height="2rem" width="25rem" class="mb-4" borderRadius="16px"></Skeleton>
						<div v-else class="meta">
							<div v-if="post.type == 'post'">
								<span class="author">{{ post.user?.name }}</span>, <span class="date">{{ $filter_date(post.created_at) }}</span>
							</div>
						</div>
					</div>

					<Skeleton v-if="skeleton.post" width="100%" height="200px" class="mb-4"></Skeleton>
					<Image v-else-if="post.image && !skeleton.post" :src="postImage" imageClass="img-responsive" alt="" preview/>

					<div v-for="i in 3" :key="i">
						<Skeleton v-if="skeleton.post" height="2rem" width="100%" class="mb-2" borderRadius="16px"></Skeleton>
						<Skeleton v-if="skeleton.post" height="2rem" width="90%" class="mb-2" borderRadius="16px"></Skeleton>
					</div>
					<div v-html="post.content"></div>

				</div>	

				<Skeleton v-if="skeleton.post" height="3rem" width="50%" class="mb-2 mt-2" borderRadius="10px"></Skeleton>
				<div v-else-if="post.type == 'post' && !skeleton.post" class="single-topic">
					<span>Kategori:</span> 
					<ul v-for="(category, i) in post.category" :key="i" class="tags">
						<li><router-link :to="`/c/${category.id}/${category.slug}`">{{ category.name }}</router-link></li>
					</ul>
				</div>

				<div class="clearfix"></div>
				<div class="margin-bottom-10"></div>
				<div class="clearfix"></div>

				<!-- <div class="single-share">
					<span>Share:</span> 
					<div class="post-share">
						<a href="#"><i class="fa fa-facebook"></i> Share</a>
						<a href="#"><i class="fa fa-twitter"></i> Tweet</a>
						<a href="#"><i class="fa fa-pinterest"></i> Pin it</a>
						<a href="#"><i class="fa fa-envelope"></i></a>
					</div>
				</div> -->

                <div class="margin-bottom-30"></div>

				<!-- <h3 class="heading-1"><span>5 Komentar</span></h3>

				<div class="comments-list margin-bottom-20">
					<div class="comment-content">
						<img src="images/comments/avatar.png" alt=""/>

						<h5><b>Robert Gourley</b> <span class="pull-right">Sep 27, 2015 at 12:17 pm</span></h5>
						<p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
						<a href="#" class="reply">Reply</a>
					</div>

					<div class="comment-content">
						<img src="images/comments/avatar2.png" alt=""/>

						<h5><b>Robert Gourley</b> <span class="pull-right">Sep 27, 2015 at 12:17 pm</span></h5>
						<p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
						<a href="#" class="reply">Reply</a>

						<div class="sub-comment">
							<img src="images/comments/avatar.png" alt=""/>
							<h5><b>Robert Gourley</b> <span class="pull-right">Sep 27, 2015 at 12:17 pm</span></h5>
							<p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
							<a href="#" class="reply">Reply</a>
						</div>
					</div>

				</div>

				<h3 class="heading-1"><span>Komentari</span></h3>

				<form class="post-comment-form">
					<div class="field">
                        <label>Konten</label>
                        <Editor
                            v-model="commentForm.content"
                            :class="{ 'p-invalid': commentForm.errors.has('content') }"
                            :api-key="$tinymce_api_key"
                            :init="{
                                height: 130,
                                plugins: 'print preview paste importcss searchreplace autolink directionality code visualblocks visualchars fullscreen image link media codesample table charmap hr nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap emoticons',
                                menubar: false,
                                toolbar: 'undo redo | bold italic underline strikethrough | charmap emoticons | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | charmap emoticons | fullscreen  preview print | image media link anchor codesample | ltr rtl',
                                valid_children: '+body[style]'
                            }"
                        />
                        <small class="p-error" v-show="commentForm.errors.has('content')">
                            {{ commentForm.errors.get('content') }}
                        </small>
					</div>

					<div class="field">
                        <Button @click="submitComment" label="Kirim" :disabled="commentForm.busy" :icon="(commentForm.busy) ? 'pi pi-spin pi-spinner' : 'pi pi-send'" class="p-button-primary"/>
                    </div>
				</form> -->

			</div>
	
			<!-- SIDEBAR -->
			<Sidebar :sidebar="sidebar" />
			<!-- // SIDEBAR -->

		</div>
	</div>
	<!-- // CONTENT -->
</template>

<script>
import Sidebar from '@/landing/_Sidebar.vue'

export default {
    components: {
		Sidebar,
	},
	props: ['settings', 'sidebar', 'id', 'slug'],
	data() {
		return {
			api: '/api/landing',
			post: {},
			commentForm: new this.$FormAuth({
                content: null
            }),
			skeleton: {
				post: true
			}
		}
	},
	computed: {
		postImage(){
			return (this.post.image) ? `${process.env.VUE_APP_ASSETS_FOLDER}/posts/${this.post.image}` : '';
		}
	},
	methods: {
		loadPost(id, slug){
			this.$Progress.start();
			this.$httpAuth.get(`${this.api}/post/${id}/${slug}`)
			.then((response) => {
				this.$Progress.finish();
				this.skeleton.post = false;
				this.post = response.data;
			})
			.catch((error) => {
				this.$Progress.fail();
                this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
            });
		},
		submitComment(){}
	},
	created(){
		this.loadPost(this.id, this.slug)
	},
	mounted() {
		
	},
}
</script>

<style scoped>
.img-responsive {
    width: 100%;
}
</style>